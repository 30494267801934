<h2 mat-dialog-title>Crea un nuovo messaggio</h2>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>Titolo</mat-label>
        <input matInput [(ngModel)]="message.title" placeholder="Aggiungi un titolo">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Messaggio</mat-label>
        <textarea matInput [(ngModel)]="message.message" placeholder="Scrivi qualcosa ..."></textarea>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial>Annulla</button>
    <button mat-button (click)="close()" [disabled]="!(message.title && message.message)">Salva</button>
</mat-dialog-actions>
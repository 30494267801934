<mat-toolbar>
    <span>{{ title }}</span>
    <span class="spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Main menu">
        <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <div class="user-info">
            <span>Ciao <b>{{ user }}</b>!</span>
        </div>
        <a mat-menu-item routerLink="/">
            <mat-icon>list</mat-icon>
            <span>Tutti i messaggi</span>
        </a>
        <a mat-menu-item routerLink="/login">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
        </a>
    </mat-menu>
</mat-toolbar>

<div class="page-wrapper">
    <router-outlet></router-outlet>
</div>
<div class="page-wrapper">
    <h2>Login</h2>
    <form #loginForm="ngForm" (ngSubmit)="login()">
        <mat-form-field>
            <mat-label>Username</mat-label>
            <input matInput [(ngModel)]="username" placeholder="Inserisci un nome" name="username" required>
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" aria-label="Login"
            [disabled]="!loginForm.form.valid">Login</button>
    </form>
</div>